$(function() {
    $btnAsideMenu = $('.toggle-product-categories');

    if(!$btnAsideMenu.length) return
    $asideMenu = $('.product-categories');

    $btnAsideMenu.on('click', function(e) {
        e.preventDefault();

        $asideMenu.slideToggle();
        $asideMenu.addClass('mobile');
        $btnAsideMenu.toggleClass('active');
    });
});