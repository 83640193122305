/* Behavior - behaviors/slide.js */

$(function () {
    var $slide = $('.box-slide .owl-carousel');

    if(!$slide.length) return

    $slide.owlCarousel({
        items: 1,
        lazyLoad:true,
        autoplay: true,
        loop: false,
        lazyLoad: true,
        navContainer: $('.box-slide .slide-navs-inner'),
        rewind: true,
        dots: false,
        nav: true,
        navElement: 'span',
        navSpeed: 500,
        dotsSpeed: 500,
        dragEndSpeed: 500,
        autoplaySpeed: 500,
        autoplayTimeout: 8000
    });

    var $slideNavs = $('.box-slide .slide-navs-inner.disabled');
    
    if(!$slideNavs.length) return
    $('.box-slide .slide-navs').addClass('disabled');

});

$(function () {
    var $produtos = $('.products .owl-carousel');

    if(!$produtos.length) return

    $produtos.owlCarousel({
        items: 4,
        lazyLoad:true,
        autoplay: true,
        loop: false,
        lazyLoad: true,
        navContainer: $('.products .slide-navs-inner'),
        rewind: true,
        dots: false,
        nav: true,
        navElement: 'span',
        navSpeed: 500,
        dotsSpeed: 500,
        dragEndSpeed: 500,
        autoplaySpeed: 500,
        autoplayTimeout: 6000,
        margin:30,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                nav:true
            },
            576:{
                items:2,
                nav:true
            },
            768:{
                items:3,
                nav:true
            },
            1200:{
                items:4,
                nav:true,
            }
        }
    });

    var $produtosNavs = $('.products .slide-navs-inner.disabled');
    
    if(!$produtosNavs.length) return
    $('.products .slide-navs').addClass('disabled');
});