$(function() {
    var $parentMenu = $('.product-categories li.menu-item-has-children');
    
    if(!$parentMenu.length) return

    var $asideSubmenuActive = $('.product-categories .sub-menu li.current-categoria-produtos-parent');

    $asideSubmenuActive.parent().addClass('show');
    $asideSubmenuActive.parent().slideToggle(350);
    $asideSubmenuActive.closest('.product-categories .current-categoria-produtos-parent').addClass('active');

    if(document.querySelector('.product-categories .current-menu-item')) {
        document.querySelector('.product-categories .current-menu-item').classList.add("active");
        if(document.querySelector('.product-categories .current-menu-item ul.sub-menu')){
            document.querySelector('.product-categories .current-menu-item ul.sub-menu').classList.add("show");
            document.querySelector('.product-categories .current-menu-item ul.sub-menu').style.display = "block";
        }
    }

    if(document.querySelector('.product-categories .current-categoria-produtos-parent')) {
        document.querySelector('.product-categories .current-categoria-produtos-parent').classList.add("active");
        if(document.querySelector('.product-categories .current-categoria-produtos-parent ul.sub-menu')){
            document.querySelector('.product-categories .current-categoria-produtos-parent ul.sub-menu').classList.add("show");
            document.querySelector('.product-categories .current-categoria-produtos-parent ul.sub-menu').style.display = "block";
        }
    }

    $parentMenu.on('click', '> a', function(e) {
        e.preventDefault();
        
        var $this = $(this);

        if ($this.next().hasClass('show')) {
            $this.next().slideUp(350);
            $this.next().removeClass('show');
        } else {
            $this.parent().parent().find('.product-categories li .sub-menu').removeClass('show');
            $this.parent().parent().find('.product-categories li .sub-menu').slideUp(350);
            $this.next().toggleClass('show');
            $this.next().slideToggle(350);
        }
    });
});

$(function() {
    var $parentMobileMenu = $('.mobile-nav li.menu-item-has-children');
    
    if(!$parentMobileMenu.length) return

    var $MobileSubmenuActive = $('.mobile-nav ul.sub-menu li.current-categoria-produtos-parent');

    $MobileSubmenuActive.parent().addClass('show');
    $MobileSubmenuActive.parent().slideToggle(350);
    $MobileSubmenuActive.closest('.mobile-nav li.current-categoria-produtos-parent').addClass('active');

    if(document.querySelector('.mobile-nav .current-menu-item')) {
        document.querySelector('.mobile-nav .current-menu-item').classList.add("active");
        if(document.querySelector('.mobile-nav .current-menu-item ul.sub-menu')){
            document.querySelector('.mobile-nav .current-menu-item ul.sub-menu').classList.add("show");
            document.querySelector('.mobile-nav .current-menu-item ul.sub-menu').style.display = "block";
        }
    }

    if(document.querySelector('.mobile-nav .current-categoria-produtos-parent')) {
        document.querySelector('.mobile-nav .current-categoria-produtos-parent').classList.add("active");
        if(document.querySelector('.mobile-nav .current-categoria-produtos-parent ul.sub-menu')){
            document.querySelector('.mobile-nav .current-categoria-produtos-parent ul.sub-menu').classList.add("show");
            document.querySelector('.mobile-nav .current-categoria-produtos-parent ul.sub-menu').style.display = "block";
        }
    }

    $parentMobileMenu.on('click', '> a', function(e) {
        e.preventDefault();
        
        var $this = $(this);

        if ($this.next().hasClass('show')) {
            $this.next().slideUp(350);
            $this.next().removeClass('show');
        } else {
            $this.parent().parent().find('.mobile-nav li ul.sub-menu').removeClass('show');
            $this.parent().parent().find('.mobile-nav li ul.sub-menu').slideUp(350);
            $this.next().toggleClass('show');
            $this.next().slideToggle(350);
        }
    });
});