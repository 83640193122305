

$(document).ready(function () {
  var exibirModal = true;

  // Verifica se o navegador possui suporte a Storage.
  // E verifica na variável de sessão se a modal já foi aberta.
  if (typeof (Storage) !== 'undefined' && sessionStorage.getItem('modalExibida') == 'true') {
    exibirModal = false;
  } else {
    exibirModal = true;
  }

  if (exibirModal) {
    setTimeout(function () {
      $('#open-modal').modal({ show: true });
    }, 120000);

    if (typeof (Storage) !== 'undefined') {
      sessionStorage.setItem('modalExibida', 'true');
    }
  }
});