$(function() {
    $(".main-navigation .btn-toggle-menu").click(function (event) {
        event.stopPropagation();
        $(".toggle-menu").slideToggle();
    })
    // $(document).click(function () {
    //     if ($(".toggle-menu").is(":visible")) {
    //         $(".toggle-menu").slideToggle();
    //     }
    // });    

    
});

